<template>
  <div class="home-container">
    <div class="linear-bg"></div>
    <div class="home-scroll">
      <div class="banner">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v,key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
      <div class="notice-bar">
        <van-notice-bar
            class="notice-swipe"
            left-icon="bullhorn-o"
            color="#ea433599"
            :text="this.notice"
        />
        <div class="linear-gradient"></div>
      </div>
      <!--<div class="hot-game">
        <div class="hot-title-div">
          <div>
            <span>Gói quà tặng hẹn hò dài lâu</span></div>
          <div @click="gotoMenu('/Game')">
            <span>Xem thêm</span>
            <van-icon name="arrow" color="#ddd" />
          </div>
        </div>
        <div class="hot-items-div">
          <van-grid :border = false :column-num="4" :icon-size="20">
            <van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
              <van-image class="game_item_img" :src="v.ico">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image>
              <span>{{v.name}}</span>
            </van-grid-item>
          </van-grid>
        </div>
        <div class="hot-title-div">
          <div>
            <span>GÓI BÌNH CHỌN TÌNH 1 ĐÊM CHO QUÝ BÀ</span></div>
          <div @click="gotoMenu('/Lottery?key=game1&id=10')">
            <span>Xem thêm</span>
            <van-icon name="arrow" color="#ddd" />
          </div>
        </div>
        <div class="hot-items-div">
          <van-grid :border = false :column-num="4" :icon-size="20">
            <van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem2" :key="key">
              <van-image class="game_item_img" :src="v.ico">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image>
              <span>{{v.name}}</span>
            </van-grid-item>
          </van-grid>
        </div>
      </div>-->
      <van-pull-refresh v-model="isLoading" >
        <div class="hot-recommend">
          <div class="hot-title-div">
            <div>
              <span>Phổ biến</span></div>
            <div>
              <span @click="gotoMenu('/Video')">Xem thêm</span>
              <van-icon name="arrow" color="#ddd" />
            </div>
          </div>
          <div class="movie_list_0">
            <swiper class="movie_swiper" :options="movielistSwiperOption">
              <swiper-slide v-for="(v,key) in movielist_0" :key="key" >
                <van-image class="movie_cover" @click="toPlayVideo(v.id)"  round :src="v.cover">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <img class="hot" v-if="key === 0" src="/img/home/no1.png">
                <img class="hot" v-if="key === 1" src="/img/home/no2.png">
                <img class="hot" v-if="key === 2" src="/img/home/no3.png">
                <div class="movie-list-item-bottom">
                  <div class="movie-time-div">
                    <span>{{v.title}}</span>
                    <div class="van-count-down">{{v.time}}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="hot-title-div">
            <div>
              <span>Đề xuất</span></div>
            <div @click="gotoMenu('/Video')">
              <span>Xem thêm</span>
              <van-icon name="arrow" color="#ddd" />
            </div>
          </div>
          <div class="movie_list_1">
            <div class="movie-list-item" v-for="(v,key) in movielist_1" :key="key" @click="toPlayVideo(v.id)">
              <van-image class="cover_img"  round :src="v.cover">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image>
              <div class="movie-list-item-bottom">
                <div class="movie-time-div">
                  <span>{{v.title}}</span>
                  <span>Xem:{{v.count}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="hot-recommend-more" @click="gotoMenu('/Video')">Xem thêm</div>
        </div>
      </van-pull-refresh>
                  <van-popup class="mask popup-z" get-container="body" v-model="popup">
                      <div class="title-popup">{{this.popupUser.length > 0 ? this.popupUser[0].name : ''}}</div>
                      <div class="content-popup">
                        {{this.popupUser.length > 0 ? this.popupUser[0].text : ''}}
                      </div>
                      <div class="footer-popup">
                        <button @click="closePopup()">Đóng</button>
                      </div>
                  </van-popup> 
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: "Nhận......",
      noticeUser: {},
      popupUser:{},
      popup: localStorage.getItem('popup') == "true" ? true : false,
      banners: [{}],
      basicData:[],
      gameitem: [{},{},{},{}],
      gameitem2: [{},{},{},{}],
      movielist_0: [{},{},{},{}],
      movielist_1: [{},{},{},{},{},{},{},{}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed:800,
        autoplay:true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows : true
        }
      }
    };
  },
  methods:{
    gotoMenu(router){
      this.$router.replace(router)
    },
    toLottery(key,id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/Lottery?key='+key+"&id="+id})
      }

    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast("Làm mới thành công");
      }, 500);
    },
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res=>{
        this.basicData = res.data;
        this.getNotice(this.basicData);
        this.getBanner(this.basicData);
        this.getGameItem();
        this.getGameItem2();
        this.getMovieList_0(this.basicData);
        this.getMovieList_1(this.basicData);
      })

    },
    getNotice(data){
      this.notice = data.notice;
    },
    getNoticeList(){
      this.$http({
        method: 'get',
        url: 'sys_get_notice_list'
      }).then(res0=>{
        this.noticeUser = res0.data;
        this.$http({
          method: 'get',
          url: 'user_info'
        }).then(res=>{
          if(res.code === 200){
            if (!localStorage.getItem('popup')) {
              this.popup = this.noticeUser.some(function(item) {
                return item.user === res.data.username;
              });
            }
            /*localStorage.setItem('popup', 
              this.noticeUser.some(function(item) {
                return item.user === res.data.username;
              })
            );*/
            this.popupUser = (this.noticeUser).filter(function(item) {
              if (item.user === res.data.username) {
                return item;
              }
            });
          }
        })
      })
    },
    closePopup() {
      localStorage.setItem('popup',false);
      this.popup = false
    },
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res=>{
        this.gameitem = res.data
      })
    },
    getGameItem2(){
      this.$http({
        method: 'get',
        url: 'lottery_hot2'
      }).then(res=>{
        this.gameitem2 = res.data
      })
    },
    getMovieList_0(data){
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data){
      this.movielist_1 = data.movielist_1
    },
    getBanner(data){
      this.banners = data.banners;
    }
  },
  mounted () {

  },
  created() {
    this.getBasicConfig();
    this.getNoticeList();
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size:30px;
@movie-list-item-bottom-size:25px;
.linear-bg{
  height: 18vh;
  background: transparent;
}
.linear-gradient{
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), #c24491, rgba(126, 86, 120, 0));
}
::v-deep .van-notice-bar {
  background: transparent;
}
::v-deep .van-notice-bar__left-icon, .van-notice-bar__right-icon {
  min-width:30px;
}
.notice-swipe{
  width: 100%;
  height: 45px;
  margin-top: 15px;
  font-size: 16px;
}
::v-deep .van-icon-bullhorn-o::before {
  transform: scale(1.5);
}
.banner{
  width: 100%;
  margin-top: -23%;
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: auto;
    aspect-ratio: 1.75;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
.banner_img{
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.hot-game{
  width: 100%;
  height: 100%;
  background: #333;
}
.hot-title-div{
  width: 100%;
  padding: 0 10px;
  margin: 0 0 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #444;
}
.hot-title-div div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 80px);
}
.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: .85rem;
  font-weight: 700;
  color: #c24491;
  white-space: nowrap;
}
@media (min-width: 550px) {
  .hot-title-div>div:first-child span{ 
    font-size: 1rem;
  }
}
.hot-title-div > div:nth-child(2) {
  width: 75px;
}
.hot-title-div > div:nth-child(2) span{
  font-size: 12px;
  color: #ddd;
}
.hot-title-div>div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 22px;
  background-color: #775fd9;
  border-radius: 1px;
  margin-right: 5px;
}
.hot-game .hot-items-div{
  margin-top: -3px;
}
.hot-game .hot-items-div span{
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
}
.hot-recommend{
  width: 100%;
  flex: 1;
  background-color: #2b2b2b;
}
.movie_swiper{
  .swiper-slide {
    width: 80%;
  }
}
.movie_list_0{
  width: calc(100% - 20px);
  margin: 0 auto;
}
.movie_cover{
  border-radius: 10px;
  width:90%;
  height: auto;
  padding-bottom: 50%;
}
.movie_list_0 .movie-list-item-bottom{
  position: relative;
  width:90%;
  bottom: 38px;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
  padding: 7px 0;
}
.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 14px;
  color: #fff;
  padding-right: 3px;
}
.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius:  0 0 10px 10px;
}
.movie_list_0 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 45px;
}
.movie_list_0 span{
  font-size: 16px;
}
.movie_list_1{
  display: grid;
  width: calc(100% - 20px);
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px 8px ;
}
.movie_list_1 .movie-list-item .cover_img{
  border-radius: 10px;
  width:100%;
  position: relative;
  padding-bottom: 85%;
}
.home-scroll{
  padding-bottom: 85px;
}
.movie_list_1 .movie-list-item{
  position: relative;
}
.movie_cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.movie_list_1 .movie-list-item-bottom{
  position: absolute;
  width: 100%;
  bottom: 5px;

}
.movie_list_1 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}
.movie_list_1 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 60%;
  padding-left: 8px;
  font-size: 14px;
}
.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  padding-right:3px;
  font-size: 14px;
}
.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 60%;
  padding-left: 10px;
  font-size: 14px;
}

.hot-recommend-more{
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #ddd;
  font-size: 15px;
  margin-top: 10px;
}
.hot-items-div .game_item_img{
  width: 60px;
  height: 60px;
}
::v-deep .hot-items-div .game_item_img .van-image__img{
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 16px;
}
::v-deep .van-grid {
  align-items: center;
}
::v-deep .van-grid > .van-grid-item {
  flex: 1;
}
::v-deep .van-grid-item__content {
  background: #333;
}
</style>
