import Vue from 'vue'

import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        
        userInfo: {},
        baseInfo:{}

    },
    mutations: {
        setUserInfoValue(state,Value){
            state.userInfo = Value
        },
        setBaseInfoValue(state,Value){
            state.baseInfo = Value
        }
    },
    getters: {
        getUserInfo: state => state.userInfo,
        getBaseInfo: state => state.baseInfo,
    },
    actions: {
        
    },
    modules: {
        
    }
})