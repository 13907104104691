export default {
	'sys_config': '/system/config', 
	'sys_get_notice_list': '/notice/getNoticeList', 
	'sys_get_banks': '/system/getBankList', 
	'video_class': '/video/itemlist', 
	'video_get_info': '/video/getVideoInfo', 
	'video_get_more_item': '/video/getVideoInfoItem', 
	'lottery_class': '/Lottery/itemlist', 
	'lottery_hot': '/Lottery/hotLottery', 
	'lottery_hot2': '/Lottery/hotLottery2',
	'lottery_list': '/Lottery/lotteryList', 
	'lottery_get_info': '/Lottery/getLotteryInfo', 
	'lottery_get_one_list': '/Lottery/getLotteryOneList', 
	'lottery_get_peilv': '/Lottery/getLotteryPeilv', 
	'video_list': '/video/getVideoList', 
	'member_login': '/member/doLogin', 
	'member_register': '/member/doRegister', 
	'base_info': '/system/base', 
	'user_info': '/member/getUserInfo', 
	'user_header_img': '/member/uploadHeaderImg', 
	'user_get_bank': '/member/getUserBankInfo', 
	'user_get_withdraw_list': '/member/getUserWithdrawList', 
	'user_get_recharge_list': '/member/getUserRechargeList',
	'user_get_game_list': '/member/getUserGameList', 
	'user_set_bank': '/member/setBank', 
	'user_set_name': '/member/setName', 
	'user_set_sex': '/member/setSex', 
	'user_set_paypw': '/member/setPayPassword', 
	'user_set_loginpw': '/member/setLoginPassword', 
	'user_get_withdraw_role': '/member/getUserWithdrawRole', 
	'user_get_personalreport': '/member/getPersonalreport', 
	'user_set_withdraw': '/member/setUserWithdraw', 
	'user_set_recharge': '/member/setUserRecharge',
	'game_place_order': '/game/placeOrder', 
	'address_list': '/xuanfei/address', 
	'xuanfeilist': '/xuanfei/xuanfeilist', 
	'xuanfeidata': '/xuanfei/xuanfeidata', 
}
